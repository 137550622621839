import tippy from 'tippy.js';

document.querySelectorAll('.js-tooltip').forEach(el => {
    tippy(el, {
        content: el.querySelector('.js-tooltip-content')?.innerHTML,
        allowHTML: true,
        interactive: true,
        theme: 'light'
    });
});
