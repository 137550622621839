class Video {
    constructor(el) {
        this.container = el;
        this.inner = this.container.querySelector('.js-video-inner');
        this.btn = this.container.querySelector('.js-video-play');
        this.template = this.container.querySelector('.js-video-template');

        this.bindClick();
    }

    bindClick() {
        this.btn?.addEventListener('click', () => {
            const code = this.template?.innerHTML;
            
            if (code) {
                this.inner.innerHTML = code;
                this.container.classList.add('_is-active');
            }
        });
    }
}

document.querySelectorAll('.js-video').forEach(el => {
    new Video(el);
});
