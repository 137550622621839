import Swiper from 'swiper';
import { Navigation, Keyboard } from 'swiper/modules';

export let galleryPopup;

class GPopup {
    constructor(el) {
        this.container = el;
        this.swiperContainer = this.container.querySelector('.js-gallery-popup-swiper');
        this.swiperWrapper = this.container.querySelector('.js-gallery-popup-swiper-wrapper');
        this.closeBtn = this.container.querySelector('.js-gallery-popup-close');
        this.handleClose();
        this.isOpen = false;
    }


    open(data) {
        if (!data || !data.items.length) {
            return;
        }

        this.buildSlides(data.items);
        this.initSlider(data.index);
        this.initVideos();
        this.isOpen = true;
        this.container.classList.add('_is-open');
    }


    buildSlides(items) {
        if (this.swiper) {
            this.swiper.destroy(true, true);
        }

        let html = '';

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            
            if (item.youtubeId) {
                html += `
                    <div class="swiper-slide">
                        <div class="swiper-slide__inner">
                            <div class="gallery-popup__video js-gallery-popup-video" data-youtube-id="${item.youtubeId}">
                                <div class="gallery-popup__video-inner js-gallery-popup-video-inner"></div>
                                <div class="gallery-popup__video-preview js-gallery-popup-video-play">
                                    <img src="https://img.youtube.com/vi/${item.youtubeId}/maxresdefault.jpg" alt="${item.text}" loading="lazy" />
                                    <button class="gallery-popup__video-play">
                                        <span>Воспроизвести</span>
                                    </button>
                                </div>
                                <div class="swiper-lazy-preloader"></div>
                            </div>
                        </div>   
                    </div>
                `;
            } else {
                html += `
                    <div class="swiper-slide">
                        <div class="swiper-slide__inner">
                            <img
                                src="${item.src}"
                                alt="${item.text}"
                                loading="lazy"
                            />
                        </div>
                        <div class="swiper-lazy-preloader"></div>    
                    </div>
                `;
            }
        }

        this.swiperWrapper.innerHTML = html;
    }


    initSlider(index) {
        this.swiper = new Swiper(this.swiperContainer, {
            modules: [ Navigation, Keyboard ],
            speed: 400,
            loop: true,
            initialSlide: index,
            keyboard: {
                enabled: true
            },
            navigation: {
                prevEl: this.container.querySelector('.js-gallery-popup-prev'),
                nextEl: this.container.querySelector('.js-gallery-popup-next')
            }
        });
    }


    initVideos() {
        const videoSlides = this.container.querySelectorAll('.js-gallery-popup-video');
        
        videoSlides.forEach(videoContainer => {
            const youtubeId = videoContainer.dataset.youtubeId;
            const videoInner = videoContainer.querySelector('.js-gallery-popup-video-inner');
            const playBtn = videoContainer.querySelector('.js-gallery-popup-video-play');

            // Add iframe
            playBtn?.addEventListener('click', () => {
                if (youtubeId) {
                    videoInner.innerHTML = `<iframe src="https://www.youtube.com/embed/${youtubeId}?title=0&byline=0&portrait=0&autoplay=1&rel=0" frameborder="0" allowfullscreen allow="autoplay"></iframe>`;
                }

                videoContainer.classList.add('_is-playing');
            });

            // Remove iframe
            this.swiper.on('slideChangeTransitionEnd', () => {
                videoContainer.classList.remove('_is-playing');
                videoInner.innerHTML = '';
            });
        });
    }


    handleClose() {
        this.closeBtn?.addEventListener('click', () => {
            this.close();
        });

        document.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.close();
            }
        });
    }


    close() {
        if (!this.isOpen || this.swiper.destroyed) {
            return false;
        }

        this.container.classList.remove('_is-open');

        setTimeout(() => {
            this.swiper.destroy(true, true);
            this.swiperWrapper.innerHTML = '';
        }, 500);
    }
}

if (document.querySelector('.js-gallery-popup')) {
    galleryPopup = new GPopup(document.querySelector('.js-gallery-popup'));
}
