import autoComplete from '@tarekraafat/autocomplete.js';

document.querySelectorAll('.js-autocomplete-player').forEach(inputElement => {
    new autoComplete({
        selector: () => inputElement,
        data: {
            src: async () => {
                try {
                    const source = await fetch(inputElement.dataset.src);
                    const data = await source.json();
          
                    return data;
                } catch (error) {
                    return error;
                }
            },
            cache: true,
            keys: ['name', 'id'],
        },
        threshold: 2,
        events: {
            input: {
                selection(event) {
                    const value = event.detail.selection.value;
                    inputElement.value = `${value['name']} (${value['id']})`;

                    if (inputElement.dataset.autosubmit === 'true') {
                        inputElement.closest('form').submit();
                    }
                }
            }
        },
        resultItem: {
            element: (item, data) => {
                const img = data.value.img ? `<img src="${data.value.img}" />` : '';
        
                item.innerHTML = `
                    <div class="autocomplete-item">
                        <div class="autocomplete-item__player">
                            <div class="autocomplete-item__player-img _${data.value.gender}">
                                ${img}
                            </div>
                            <div class="autocomplete-item__player-name">
                                ${data.value.name}
                                <span>(${data.value.id})</span>
                            </div>
                        </div>
                    </div>
                `;
            },
            highlight: false
        }
    });
});
