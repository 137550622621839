document.querySelectorAll('.js-show-password').forEach(btn => {
    btn.addEventListener('click', () => {
        const input = btn.parentNode.querySelector('.js-password');

        if (input?.getAttribute('type') === 'password') {
            input?.setAttribute('type', 'text');
        } else {
            input?.setAttribute('type', 'password');
        }
    });
});
