document.querySelectorAll('.js-input-file').forEach(el => {
    const input = el.querySelector('input[type="file"]');
    const label = el.querySelector('.js-filename');

    input?.addEventListener('change', () => {
        // File name from path
        const filename = input.value.replace(/^.*[\\\/]/, '');

        label?.innerText = filename.length ? filename : 'Загрузите фото профиля';        
    });
});
