import { GLOBALS } from './globals';

/**
 * Check if the device is mobile or desktop
 */
if ('ontouchstart' in window) {
    GLOBALS.mobile = true;
    GLOBALS.H.classList.add('mobile');
} else {
    GLOBALS.H.classList.add('desktop');
}


/**
 * Detect OS
 */
if (navigator.userAgent.match(/Android/i)) {
    GLOBALS.platform = 'android';
    GLOBALS.H.classList.add('android');
} else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    GLOBALS.platform = 'ios';
    GLOBALS.H.classList.add('ios');
} else if (navigator.userAgent.match(/Safari/i) && GLOBALS.mobile === true) {
    GLOBALS.platform = 'ios';
    GLOBALS.H.classList.add('ios');
}
