import SlimSelect from 'slim-select';

document.querySelectorAll('.js-select').forEach(el => {
    new SlimSelect({
        select: el,
        settings: {
            showSearch: false
        }
    });
});
