import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

document.querySelectorAll('.js-scroll-to').forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();

        let href = link.getAttribute('href');
        let target = document.querySelector(href);

        if (target) {
            gsap.to(window, {
                duration: 0,
                scrollTo: {
                    y: target,
                    offsetY: 60
                }
            });
        }
    });
});
