import { GLOBALS } from '../../js/globals';

document.querySelectorAll('.js-open-event-register').forEach(btn => {
    btn.addEventListener('click', (e) => {
        e.preventDefault();
        GLOBALS.H.classList.add('_has-open-register-popup');
    });
});

document.querySelectorAll('.js-close-event-register').forEach(btn => {
    btn.addEventListener('click', () => {
        GLOBALS.H.classList.remove('_has-open-register-popup');
    });
});


document.querySelectorAll('.js-form-partner-radio input').forEach(radio => {
    radio.addEventListener('change', () => {
        if (+radio.dataset.value === 1) {
            document.querySelector('.js-form-partner-name')?.classList.add('_is-active');
        } else {
            document.querySelector('.js-form-partner-name')?.classList.remove('_is-active');
        }
    });
});
