class Tabs {
    constructor(el) {
        this.tabs = el;
        this.links = this.tabs.querySelectorAll('a');
        this.id = this.tabs.dataset.id;
        this.targets = document.querySelectorAll(`.js-tabs-container[data-id="${this.id}"] .js-tabs-target`);

        this.bindLinks();
    }

    bindLinks() {
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault();

                this.filter( link.getAttribute('href')?.substring(1) );

                this.links.forEach(a => {
                    a.classList.remove('_is-active');
                });

                link.classList.add('_is-active');
            });
        });
    }

    filter(id) {
        this.targets.forEach(target => {
            if (target.dataset.id === id) {
                target.classList.add('_is-active');
            } else {
                target.classList.remove('_is-active');
            }
        });
    }
}

document.querySelectorAll('.js-tabs-local').forEach(el => {
    new Tabs(el);
});
