import axios from 'axios';
import { galleryPopup } from '../gallery-popup/gallery-popup.js';


class Gallery {
    constructor(el) {
        this.container = el;
        this.grid = this.container.querySelector('.js-gallery-grid');
        this.loadBtn = this.container.querySelector('.js-gallery-more');

        this.bindLoadBtn();
        this.bindPopupOpen();
    }


    bindLoadBtn() {
        this.loadBtn?.addEventListener('click', () => {
            this.loadMore();
        });
    }


    loadMore() {
        this.loadBtn.disabled = true;

        axios.get(this.container.dataset.url, {
            params: {
                id: this.container.dataset.id,
                offset: this.container.querySelectorAll('.js-gallery-item').length,
                portion: this.container.dataset.portion
            }
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    this.grid.insertAdjacentHTML('beforeend', response.data.content);
                    this.bindPopupOpen();

                    if (!response.data.rest) {
                        this.loadBtn.style.display = 'none';
                    }
                } else {
                    console.log(`Ошибка загрузки данных\n${response.data.message}`);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loadBtn.disabled = false;
            });
    }


    bindPopupOpen() {
        const items = this.container.querySelectorAll('.js-gallery-item:not(._is-loaded)');

        items.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                const popupData = {
                    index: [...items].indexOf(item),
                    items: []
                };

                items.forEach(x => {
                    popupData.items.push({
                        src: x.dataset.src,
                        youtubeId: x.dataset.youtubeId,
                        text: x.dataset.text
                    });
                });

                galleryPopup.open(popupData);
            });

            item.classList.add('_is-loaded');
        });
    }
}

document.querySelectorAll('.js-gallery').forEach(el => {
    new Gallery(el);
});
