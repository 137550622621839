import axios from 'axios';

document.querySelectorAll('.js-request-action').forEach(btn => {

    btn.addEventListener('click', e => {
        e.preventDefault();
        
        const { url, id, action } = btn.dataset;

        if (url && id && action) {
            btn.disabled = true;

            axios({
                method: 'POST',
                url: url,
                data: {
                    action: action,
                    id: id
                }
            })
                .then((response) => {
                    if (response.data.status === 'success') {
                        if (action === 'accept') {
                            btn.closest('.js-request-item-cell')?.innerHTML = '<div class="tag tag--orange-2">Приглашение принято</div>';
                        } else if (action === 'reject') {
                            btn.closest('.js-request-item')?.classList.add('_is-rejected');
                            btn.closest('.js-request-item-cell')?.innerHTML = '<div class="tag tag--orange-3">Отклонено</div>';
                        }
                    } else {
                        console.log(`Ошибка загрузки данных\n${response.data.message}`);
                    }
                })
                .catch((error) => {
                    console.log('Ошибка загрузки данных');
                    console.log(error);
                })
                .finally(() => {
                    btn.disabled = false;
                });
        }
    });
});
