import axios from 'axios';

class Calendar {
    constructor(el) {
        this.container = el;
        this.grid = this.container.querySelector('.js-calendar-grid');
        this.loadBtn = this.container.querySelector('.js-calendar-more');
        this.filter = this.container.querySelector('.js-calendar-filter');
        this.filterSelects = this.filter?.querySelectorAll('.js-select');

        this.bindLoadBtn();
        this.bindFilter();
    }


    bindLoadBtn() {
        this.loadBtn?.addEventListener('click', () => {
            this.loadMore();
        });
    }


    bindFilter() {
        this.filterSelects?.forEach(select => {
            select.addEventListener('change', () => {
                this.reloadFilter();
            });
        });
    }


    reloadFilter() {
        this.filter.submit();
    }


    loadMore() {
        this.loadBtn.disabled = true;
        
        const params = {
            id: this.container.dataset.id,
            offset: this.container.querySelectorAll('.js-calendar-item').length,
            portion: this.container.dataset.portion
        }
        
        this.filterSelects?.forEach(select => {
            if (select.value) {
                params[select.name] = select.value;
            }
        });


        axios.get(this.container.dataset.url, {
            params: params
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    this.grid.insertAdjacentHTML('beforeend', response.data.content);

                    if (!response.data.rest) {
                        this.loadBtn.style.display = 'none';
                    }
                } else {
                    console.log(`Ошибка загрузки данных\n${response.data.message}`);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loadBtn.disabled = false;
            });
    }
}

document.querySelectorAll('.js-calendar').forEach(el => {
    new Calendar(el);
});


document.addEventListener('click', e => {
    if (e.target.closest('.js-calendar-item-remove')) {
        e.preventDefault();

        const btn = e.target.closest('.js-calendar-item-remove');
        const { url, id } = btn.dataset;

        if (url && id) {
            btn.disabled = true;

            axios({
                method: 'POST',
                url: url,
                data: {
                    id: id
                }
            })
                .then((response) => {
                    if (response.data.status === 'success') {
                        const item = btn.closest('.js-calendar-item');
                        item?.parentNode.removeChild(item);
                    } else {
                        console.log(`Ошибка загрузки данных\n${response.data.message}`);
                    }
                })
                .catch((error) => {
                    console.log('Ошибка загрузки данных');
                    console.log(error);
                })
                .finally(() => {
                    btn.disabled = false;
                });
        }
    }
});

document.addEventListener('click', e => {
    if (e.target.closest('.js-player-request')) {
        e.preventDefault();

        const btn = e.target.closest('.js-player-request');
        const { url, id } = btn.dataset;

        if (url && id) {
            btn.disabled = true;

            axios({
                method: 'POST',
                url: url,
                data: {
                    id: id
                }
            })
                .then((response) => {
                    if (response.data.status === 'success') {
                        btn.innerHTML = '<span>Заявка отправлена</span>';
                    } else {
                        console.log(`Ошибка загрузки данных\n${response.data.message}`);
                    }
                })
                .catch((error) => {
                    console.log('Ошибка загрузки данных');
                    console.log(error);
                })
                .finally(() => {
                    btn.disabled = false;
                });
        }
    }
});
