document.querySelectorAll('.js-user').forEach(container => {
    const trigger = container.querySelector('.js-user-trigger');

    trigger?.addEventListener('click', (e) => {
        e.preventDefault();
        container.classList.toggle('_is-active');
    });

    document.addEventListener('click', e => {
        if (e.target !== container && !container.contains(e.target)) {
            container.classList.remove('_is-active');
        }
    });
});
