import { GLOBALS } from '../../js/globals';

const desktopWidth = 1100;

document.querySelectorAll('.js-open-menu-hover').forEach(btn => {
    btn.addEventListener('mouseenter', () => {
        if (!GLOBALS.mobile && window.innerWidth >= desktopWidth) {
            GLOBALS.H.classList.add('_has-open-menu');
        }
    });

    btn.addEventListener('mouseleave', () => {
        if (!GLOBALS.mobile && window.innerWidth >= desktopWidth) {
            GLOBALS.H.classList.remove('_has-open-menu');
        }
    });        
});

document.querySelectorAll('.js-open-menu-click').forEach(btn => {
    btn.addEventListener('click', () => {
        GLOBALS.H.classList.toggle('_has-open-menu');
    });
});

document.querySelectorAll('.js-close-menu').forEach(btn => {
    btn.addEventListener('click', () => {
        GLOBALS.H.classList.remove('_has-open-menu');
    });
});
