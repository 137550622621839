import gsap from 'gsap';

const container = document.querySelector('.js-hero');
const bg = document.querySelector('.js-hero-bg');
let limitScroll = window.innerHeight;

const update = () => {
    const currentScroll = window.scrollY;
    const deltaScroll = currentScroll / limitScroll;
    const opacity = Math.min(deltaScroll * 0.7, 0.7);

    if (currentScroll > limitScroll * 1.5) {
        container.classList.add('_is-hidden');
    } else {
        container.classList.remove('_is-hidden');

        gsap.to(bg, {
            backgroundColor: `rgba(0, 0, 0, ${opacity})`,
            duration: 0
        });
    
        gsap.to(bg, {
            backdropFilter: `blur(${7 * opacity}px)`,
            duration: 0
        });
    }
};

if (container && bg) {
    update();

    document.addEventListener('scroll', update);

    window.addEventListener('resize', () => {
        limitScroll = window.innerHeight;
    });
}
