import { validatedInput } from '../../js/validate.js';
import axios from 'axios';

class Form {
    constructor(el) {
        this.form = el;
        this.container = this.form.closest('.js-form-container') || this.form;
        this.submitBtn = this.container.querySelector('.js-form-submit');
        this.validators = [];
        this.errorsCount = 0;
        this.formTitle = this.container.querySelector('.js-form-title');
        this.formResult = this.container.querySelector('.js-form-result');
        this.formMessage = this.container.querySelector('.js-form-message');
        this.noAjax = this.form.hasAttribute('data-noajax');
        this.noReset = this.form.hasAttribute('data-noreset');

        this.bindValidation();
        this.bindSubmit();
        this.bindClose();
    }


    bindValidation() {
        const inputs = this.container.querySelectorAll('[data-validate]');

        inputs.forEach(input => {
            let pair = null;

            if (+input.dataset.validatePair === 2) {
                pair = this.container.querySelector('[data-validate-pair="1"]'); // Надо придумать получше
            }
            
            this.validators.push(
                new validatedInput(input, pair)
            );
        });
    }


    bindSubmit() {
        this.form.addEventListener('submit', (e) => {
            this.validate();

            if (this.errorsCount === 0) {
                if (!this.noAjax) {
                    this.sendForm();
                    e.preventDefault();
                }
            } else {
                e.preventDefault();
            }
        });
    }


    async sendForm() {
        this.submitBtn.disabled = true;
        this.container.classList.remove('_is-sent');

        axios({
            method: this.form.getAttribute('method'),
            url: this.form.getAttribute('action'),
            data: this.form,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then((response) => {
                this.renderResult(response.data);

                // Успех
                if (response.data.status === 'success') {

                    // Редирект если нужен (например, авторизация)
                    if (response.data.redirect) {
                        window.location.href = response.data.redirect;
                    }

                    if (!this.noReset) {
                        this.resetForm();
                    }
                } else {
                    console.log(`Ошибка загрузки данных\n${response.data.message}`);
                    this.renderMessage(response.data);
                }
            })
            .catch((error) => {
                console.log('Ошибка загрузки данных');
                console.log(error);

                this.renderResult({
                    content: `<div class="text">
                                <p><em>Произошла ошибка. Попробуйте позже.</em></p>
                                <button type="button" class="btn btn--large btn--filled js-form-ok">
                                    <span>Понятно</span>
                                </button>
                            </div>`
                });
                /*this.renderMessage({ 
                    message: 'Произошла ошибка. Попробуйте позже.'
                });*/
            })
            .finally(() => {
                this.container.classList.add('_is-sent');
                this.submitBtn.disabled = false;
            });
    }


    validate() {
        this.errorsCount = 0;

        this.validators.forEach(v => {
            v.validate();

            if (v.result.status === 'error') {
                this.errorsCount++;
            }
        });
    }


    resetForm() {
        this.form.reset();

        this.validators.forEach(v => {
            v.reset();
        });
    }


    renderResult(data) {
        if (this.formTitle && data.title) {
            this.formTitle.innerHTML = data.title;
        }
        
        if (this.formResult && data.content) {
            this.formResult.innerHTML = data.content;
            this.container.classList.add('_has-result');
        }
    }


    renderMessage(data) {
        if (this.formMessage && data.message) {
            this.formMessage.innerHTML = data.message;
        }
    }


    bindClose() {
        this.container.addEventListener('click', e => {
            if (e.target.closest('.js-form-ok')) {
                // this.renderResult({'content': ' '});
                this.container.classList.remove('_has-result');
            }
        });
    }
}


document.querySelectorAll('.js-form').forEach(el => new Form(el));
