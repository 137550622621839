function validate(params) {
    const { value, value2, rules, matrix, checked, visible } = params;

    const result = {
        status: '',
        message: ''
    };

    
    // Required
    if (rules.required) {
        if (value.length) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Обязательное поле';
            return result;
        }
    } else {
        if (value.length) {
            result.status = 'ok';
        }
    }


    // Required if visible
    if (rules.requiredIfVisible && visible) {
        if (value.length) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Обязательное поле';
            return result;
        }
    } else {
        if (value.length) {
            result.status = 'ok';
        }
    }


    // Required checkbox
    if (rules.checkbox) {
        if (checked) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Обязательное поле';
            return result;
        }
    } else {
        result.status = 'ok';
    }

    
    // Email
    if (rules.email) {
        if (/^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i.test(value) || !value.length) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Не похоже на электронную почту';
            return result;
        }
    }

    
    // Phone number
    if (rules.phone) {
        // Using the current mask (matrix)
        const minLength = matrix ? matrix.length : 11;

        if (value.length >= minLength) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Не похоже на номер телефона';
            return result;
        }
    }


    // Date dd.mm.yyyy
    if (rules.date) {
        if (/(^0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2]).(\d{4}$)/i.test(value)) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Неверный формат даты';
            return result;
        }
    }


    // Double password
    if (rules.repeatValue) {
        if (value === value2) {
            result.status = 'ok';
        } else {
            result.status = 'error';
            result.message = 'Пароли не совпадают';
            return result;
        }
    }

    return result;
}


export class validatedInput {
    constructor(input, pair) {
        this.input = input;
        this.pair = pair;
        this.result = {};
        this.rules = {
            required: this.input.hasAttribute('data-validate-required'),
            requiredIfVisible: this.input.hasAttribute('data-validate-required-if-visible'),
            checkbox: this.input.hasAttribute('data-validate-checkbox'),
            email: this.input.hasAttribute('data-validate-email'),
            date: this.input.hasAttribute('data-validate-date'),
            phone: this.input.hasAttribute('data-validate-phone'),
            repeatValue: !!this.pair
        }

        this.bindChange();
    }

    bindChange() {
        this.input.addEventListener('input', () => {
            this.input.classList.remove('_is-invalid');
        });
    }

    validate() {
        this.result = validate({
            value: this.input.value,
            value2: this.pair ? this.pair.value : null,
            rules: this.rules,
            matrix: this.input.dataset.matrix || null,
            checked: this.input.checked,
            visible: this.checkVisibility(this.input)
        });

        if (this.result.status === 'error') {
            this.input.classList.add('_is-invalid');
        } else {
            this.input.classList.remove('_is-invalid');
        }
    }

    checkVisibility(el) {
        let v = false;
        
        try {
            v = el.checkVisibility();
        } catch (error) {
            console.log(error);
        }

        return v;
    }

    reset() {
        this.result = {};
        this.input.classList.remove('_is-invalid');
    }
}
