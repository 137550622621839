/**
 * Global variables
 */
export const GLOBALS = {
    mobile: false,
    H: document.getElementsByTagName('html')[0],
    breakpoints: {
        s: 599,
        m: 1099,
        l: 1600,
        xl: 2000
    }
};
