import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';

const smController = new ScrollMagic.Controller();

class AboutAnimation {
    constructor(el) {
        this.container = el;
        this.racketScene = null;
        this.ballsScene = null;
        this.slides = this.container.querySelectorAll('.js-hp-about-animation-item');
        this.ball2 = this.container.querySelector('.js-about-ball-2');
        this.ball2Inner = this.ball2?.querySelector('div');
        this.ball3 = this.container.querySelector('.js-about-ball-3');
        this.ball3Inner = this.ball3?.querySelector('div');

        this.handleScroll();
        this.handleUpdate();
    }

    handleScroll() {

        // Поворот ракетки
        this.racketScene = new ScrollMagic.Scene({
            triggerElement: this.container,
            triggerHook: 0.60,
            offset: 0,
            duration: window.innerHeight * 0.55,
            reverse: true
        })
            .addTo(smController)
            .on('progress', (e) => {
                const num = Math.max(1, Math.ceil(e.progress * 20));

                this.slides.forEach((slide, index) => {
                    if (20 - index === num) {
                        slide.classList.add('_is-visible');
                    } else {
                        slide.classList.remove('_is-visible');
                    }
                });
            });


        // Параллакс и вращение мячей
        this.ballsScene = new ScrollMagic.Scene({
            triggerElement: this.container,
            triggerHook: 1,
            offset: 0,
            duration: window.innerHeight + this.container.offsetHeight,
            reverse: true
        })
            .addTo(smController)
            .on('progress', (e) => {
                if (this.ball2) {
                    gsap.to(this.ball2, {
                        duration: 0,
                        y: `-${e.progress * 250}%`
                    });

                    gsap.to(this.ball2Inner, {
                        duration: 0,
                        rotate: `-${e.progress * 180}%`
                    });
                }

                if (this.ball3) {
                    gsap.to(this.ball3, {
                        duration: 0,
                        y: `-${e.progress * 650}%`
                    });

                    gsap.to(this.ball3Inner, {
                        duration: 0,
                        rotate: `${270 + e.progress * 240}%`
                    });
                }
            });
    }

    handleUpdate() {
        window.addEventListener('resize', () => {
            this.update();
        });
    }

    update() {
        this.racketScene.duration(window.innerHeight * 0.55);
        this.racketScene.refresh();

        this.ballsScene.duration(window.innerHeight + this.container.offsetHeight);
        this.ballsScene.refresh();
    }
}

document.querySelectorAll('.js-hp-about-animation').forEach(el => {
    new AboutAnimation(el);
});
