import axios from 'axios';

class Rating {
    constructor(el) {
        this.container = el;
        this.filter = this.container.querySelector('.js-rating-filter');
        this.filterSelects = this.filter?.querySelectorAll('.js-select');
        this.grid = this.container.querySelector('.js-rating-grid');
        this.loadBtn = this.container.querySelector('.js-rating-more');

        this.bindFilter();
        this.bindLoadBtn();
    }


    bindFilter() {
        this.filterSelects?.forEach(select => {
            select.addEventListener('change', () => {
                this.reloadFilter();
            });
        });
    }


    reloadFilter() {
        this.filter.submit();
    }


    bindLoadBtn() {
        this.loadBtn?.addEventListener('click', () => {
            this.loadMore();
        });
    }


    loadMore() {
        this.loadBtn.disabled = true;
        
        const params = {
            id: this.container.dataset.id,
            offset: this.container.querySelectorAll('.js-rating-item').length,
            portion: this.container.dataset.portion
        }
        
        this.filterSelects.forEach(select => {
            if (select.value) {
                params[select.name] = select.value;
            }
        });


        axios.get(this.container.dataset.url, {
            params: params
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    this.grid.insertAdjacentHTML('beforeend', response.data.content);

                    if (!response.data.rest) {
                        this.loadBtn.style.display = 'none';
                    }
                } else {
                    console.log(`Ошибка загрузки данных\n${response.data.message}`);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loadBtn.disabled = false;
            });
    }
}

document.querySelectorAll('.js-rating').forEach(el => {
    new Rating(el);
});
