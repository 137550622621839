import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';
import { GLOBALS } from '../../js/globals.js';

const smController = new ScrollMagic.Controller();

class HorizontalScroll {
    constructor(el) {
        this.container = el;
        this.inner = this.container.querySelector('.js-about-scroll-inner');
        this.screen2 = this.container.querySelector('.js-about-s2');
        this.screen3 = this.container.querySelector('.js-about-s3');
        this.screen4 = this.container.querySelector('.js-about-s4');
        this.screen5 = this.container.querySelector('.js-about-s5');
        this.screen6 = this.container.querySelector('.js-about-s6');
        this.scene = null;
        this.rules = `(min-width: ${GLOBALS.breakpoints.m + 1}px) and (max-aspect-ratio: 2.3) and (orientation: landscape)`;

        this.init();
        this.handleUpdate();
    }

    init() {
        const mql = window.matchMedia(this.rules);

        if (!mql.matches) {
            return;
        }
        
        this.scene = new ScrollMagic.Scene({
            duration: window.innerHeight * 5,
            triggerElement: document.querySelector('.wrap-page'),
            triggerHook: 0
        })
        .setPin(this.container)
        .on('progress', e => {
            gsap.to(this.inner, {
                duration: 0,
                '--progress': e.progress
            });

            gsap.to(this.screen2, {
                duration: 0,
                '--local-progress': e.progress * 5 * 0.5
            });

            gsap.to(this.screen3, {
                duration: 0,
                '--local-progress': Math.max((e.progress - 0.2) * 5 * 0.5, 0)
            });

            gsap.to(this.screen4, {
                duration: 0,
                '--local-progress': Math.max((e.progress - 0.4) * 5 * 0.5, 0)
            });

            gsap.to(this.screen6, {
                duration: 0,
                '--local-progress': Math.max((e.progress - 0.8) * 5 * 0.5, 0)
            });
        })
        .addTo(smController);

        this.container.classList.add('_is-scrollable');
    }

    handleUpdate() {
        window.addEventListener('resize', () => {
            const mql = window.matchMedia(this.rules);

            if (mql.matches) {
                if (this.scene) {
                    this.update();
                } else {
                    this.init();
                }
            } else if (this.scene) {
                try {
                    this.destroy();
                } catch (error) { }
            }
        });
    }

    update() {
        this.scene.duration(window.innerHeight * 5);
        this.scene.refresh();
    }

    destroy() {
        this.scene.destroy(true);
        this.scene = null;
        this.container.classList.remove('_is-scrollable');
    }
}

document.querySelectorAll('.js-about-scroll').forEach(el => {
    new HorizontalScroll(el);
});
