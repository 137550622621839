class TextPopup {
    constructor(el) {
        this.container = el;
        this.contentWrapper = this.container.querySelector('.js-text-popup-content');
        this.closeBtns = this.container.querySelectorAll('.js-text-popup-close');
        this.handleClose();
        this.isOpen = false;
    }


    open(data) {
        if (!data) {
            return;
        }

        this.buildContent(data);
        this.isOpen = true;
        this.container.classList.add('_is-open');
    }


    buildContent(data) {
        this.contentWrapper.innerHTML = data;
    }


    handleClose() {
        this.closeBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.close();
            });
        });

        document.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.close();
            }
        });
    }


    close() {
        if (!this.isOpen) {
            return false;
        }

        this.container.classList.remove('_is-open');

        setTimeout(() => {
            this.contentWrapper.innerHTML = '';
        }, 500);
    }
}


if (document.querySelector('.js-text-popup')) {
    const textPopup = new TextPopup(document.querySelector('.js-text-popup'));

    document.querySelectorAll('.js-open-text-popup').forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.preventDefault();
    
            const id = btn.dataset.template;
    
            if (id) {
                const template = document.querySelector(`.js-popup-content[data-id="${id}"]`)?.innerHTML;

                if (template) {
                    textPopup.open(template);
                }
            }
        });
    });
}
