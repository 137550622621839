import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

class Slider {
    constructor(el) {
        this.container = el;
        this.inner = this.container.querySelector('.js-slider-swiper');
        this.paginator = this.container.querySelector('.js-slider-pagination');
        this.prevBtn = this.container.querySelector('.js-slider-prev');
        this.nextBtn = this.container.querySelector('.js-slider-next');

        this.init();
    }

    init() {
        new Swiper(this.inner, {
            modules: [ Navigation, Pagination ],
            speed: 400,
            pagination: {
                el: this.paginator,
                type: 'fraction',
                formatFractionCurrent: (number) => {
                    return number > 9 ? number : '0' + number;
                },
                formatFractionTotal: (number) => {
                    return number > 9 ? number : '0' + number;
                }
            },
            navigation: {
                prevEl: this.prevBtn,
                nextEl: this.nextBtn
            }
        });
    }
}

document.querySelectorAll('.js-slider').forEach(el => {
    new Slider(el);
});
