import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { GLOBALS } from '../../js/globals.js';

class ProgressSlider {
    constructor(el) {
        this.container = el;
        this.inner = this.container.querySelector('.js-progress-slider-swiper');
        this.prevBtn = this.container.querySelector('.js-progress-slider-prev');
        this.nextBtn = this.container.querySelector('.js-progress-slider-next');
        this.progress = this.container.querySelector('.js-progress-slider-progress');

        this.init();
    }

    init() {
        new Swiper(this.inner, {
            modules: [ Navigation, Pagination ],
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: this.prevBtn,
                nextEl: this.nextBtn
            },
            pagination: {
                el: this.progress,
                type: 'progressbar'
            },
            breakpoints: {
                [GLOBALS.breakpoints.s + 1]: {
                    slidesPerView: 2,
                    spaceBetween: 12
                },
                [GLOBALS.breakpoints.m + 1]: {
                    slidesPerView: 3,
                    spaceBetween: 16
                },
                [GLOBALS.breakpoints.l]: {
                    slidesPerView: 4,
                    spaceBetween: 16
                }
            }
        });
    }
}

document.querySelectorAll('.js-progress-slider').forEach(el => {
    new ProgressSlider(el);
});
