import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { GLOBALS } from '../../js/globals.js';

class ProSlider {
    constructor(el) {
        this.container = el;
        this.inner = this.container.querySelector('.js-pro-slider-swiper');
        this.paginator = this.container.querySelector('.js-pro-slider-pagination');
        this.prevBtn = this.container.querySelector('.js-pro-slider-prev');
        this.nextBtn = this.container.querySelector('.js-pro-slider-next');

        this.init();
    }

    init() {
        this.swiper = new Swiper(this.inner, {
            modules: [ Navigation, Pagination ],
            loop: true,
            speed: 400,
            spaceBetween: 8,
            pagination: {
                el: this.paginator,
                type: 'fraction',
                formatFractionCurrent: (number) => {
                    return number > 9 ? number : '0' + number;
                },
                formatFractionTotal: (number) => {
                    return number > 9 ? number : '0' + number;
                }
            },
            navigation: {
                prevEl: this.prevBtn,
                nextEl: this.nextBtn
            },
            breakpoints: {
                [GLOBALS.breakpoints.s + 1]: {
                    slidesPerView: 'auto',
                    spaceBetween: 12
                },
                [GLOBALS.breakpoints.m + 1]: {
                    slidesPerView: 'auto',
                    spaceBetween: 16
                }
            },
            on: {
                slideChangeTransitionEnd: () => {
                    // this.swiper.update();
                }
            }
        });
    }
}

document.querySelectorAll('.js-pro-slider').forEach(el => {
    new ProSlider(el);
});
